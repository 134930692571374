@import "~antd/dist/antd.less";
@import "./antd.override.less";
@import "./antd.form.override.less";
@import "./antd.table.override.less";

html,
body {
    min-height: 100vh;
    width: 100%;
    overflow: hidden;
    font-family: "Montserrat", "Open Sans", sans-serif;
    font-weight: 500;
}

@media screen and (max-width: 767px) {
    body {
        overflow: auto;
    }
}

#root {
    display: flex;
    flex: 1;
    flex-direction: column;
    min-height: 100%;
}

.w-100 {
    width: 100%;
}

.row-space-between {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.p-0 {
    padding: 0px;
}

.pl-0 {
    padding-left: 0px;
}

.pr-0 {
    padding-right: 0px;
}

.mb-0 {
    margin-bottom: 0px !important;
}

.row-items-centered {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.d-flex {
    display: flex;
}

.flex-1 {
    flex: 1;
}

.align-items-center {
    align-items: center;
}

.justify-content-center {
    justify-content: center;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-start {
    justify-content: flex-start;
}

.justify-content-between {
    justify-content: space-between;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.order-qr-code-img {
    max-width: 350px;
    width: 100%;
    margin: 20px;
}

.payment-type-btn {
    width: 150px;
    height: 100px;
}

.white-space-break-spaces {
    white-space: break-spaces;
}

.container {
    max-width: 1300px;
    width: 100%;
}

.email-confirm-btn {
    display: flex;
    color: #eb5757 !important;
    font-size: 13px;
    padding: 0px !important;
    height: unset !important;
    margin: 0;
}

.email-confirm-btn span {
    text-decoration: underline;
    text-underline-offset: 2px;
}

.user-avatar-upload {
    max-width: 240px;
}

.user-avatar-upload > div {
    width: 100%;
}

.phone-input .ant-select-selection-item {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.phone-input-country-flag {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin-right: 10px;
}

.phone-input-country-flag svg {
    border-radius: 50%;
    height: 24px;
    width: 24px;
    border: 1px solid #e2e2e2;
}

.ant-select-item-option-content .phone-input-country-flag {
    display: none;
}

.card-header-wo-border .ant-card-head-wrapper {
    border: none;
}

.table-actions-column .ant-btn {
    height: 16px;
}

.order-status-span {
    border-radius: 10px;
    padding: 4px 14px;
}

.form-action-buttons .ant-btn-link {
    color: #0a0a0a;
}

.order-form-container {
    margin-top: 60px;
}

@media screen and (max-width: 767px) {
    .order-form-container {
        margin-top: 0px;
    }
}

.fw-400 {
    font-weight: 400;
}

.mw-240 {
    max-width: 240px;
    width: 100%;
}

.stripe-order-payment-modal {
    max-width: 550px;
    width: 100% !important;
}

.height-auto {
    height: auto !important;
}

.checkbox-form-item {
    .ant-form-item-control-input-content {
        border: none !important;
        height: auto !important;
    }
}

.checkbox-form-item.ant-form-item-has-error {
    .ant-form-item-control-input-content {
        background-color: transparent !important;
    }
}

.order-type-btn-image {
    border-radius: 10px;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2);
    height: 200px;
    width: 200px;
}

.order-country-flag-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.order-country-flag-img {
    border-radius: 10px;
    width: 120px;
}

.order-country-flag-img:hover,
.order-country-flag-img[data-selected="true"] {
    box-shadow: 0 0px 10px 0 #db8912;
}

.download-label-text {
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-name: download-label-text-animation;
    display: block;
    font-size: 12px;
    font-weight: bold;
    line-height: 1.2 !important;
    text-align: center;
}

@keyframes download-label-text-animation {
    0% {
        color: #0a0a0a;
    }
    50% {
        color: #ff4d4f;
    }
    100% {
        color: #0a0a0a;
    }
}
