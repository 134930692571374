@media screen and (min-width: 1200px) {
    .make-order-steps .ant-steps-item-title {
       font-size: 1vw;
    }
}

@media screen and (min-width: 1600px) {
    .make-order-steps .ant-steps-item-title {
       font-size: 16px;
    }
}