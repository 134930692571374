.menu-logo-container {
    margin: 24px 10px 0px 10px;
    text-align: center;
}

.menu-logo {
    max-height: 50px;
}

.mobile-menu-drawer {
    margin-top: 80px;
    transition: all 250ms;
    overflow-y: auto;
}

.mobile-menu-drawer {
    .ant-drawer-content {
        border-radius: 0px 0px 20px 20px;
        background-color: #fff;
        padding-bottom: 48px;
    }
    .ant-drawer-content-wrapper {
        background-color: transparent !important;
        height: auto !important;
    }
    .ant-drawer-content-wrapper-hidden {
        opacity: 0;
    }
}

.mobile-menu-drawer > * {
    box-shadow: none !important;
}

.mobile-menu {
    padding: 0px 24px;
}

.mobile-menu {
    .ant-divider-horizontal {
        margin: 8px 0px;
    }
}

.mobile-menu > div {
    margin: 0 auto;
    max-width: 500px;
}

.mobile-menu-item {
    border: none;
    border-radius: 0px;
    text-align: left;
    width: 100%;
    font-size: 16px;
    padding: 0px;
    padding: 14px 0px;
    height: auto;
    fill: #db8912;
}

.mobile-menu-item span {
    vertical-align: top;
}

.mobile-menu-item span:first-child > svg {
    width: 24px;
    height: 100%;
}

.mobile-menu-item span:first-child {
    color: #db8912;
    height: 24px;
    width: 24px;
}

.mobile-menu-item span:last-child {
    color: #e2e2e2;
    float: right;
    margin-top: 5px;
}

.mobile-menu-bottom-actions {
    display: flex;
    margin-top: 24px;
    justify-content: space-between;
}

.mobile-menu-sub-item {
    font-size: 14px;
    margin-left: 24px;
    padding: 0px !important;
}
