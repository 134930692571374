@primary-color: #db8912;
@text-color: #0a0a0a;

.ant-layout {
    background-color: #fafafa;
}

.ant-layout-sider {
    padding: 0px 32px;
}

.ant-layout-sider-trigger {
    display: none;
    left: 0px;
}

.ant-menu {
    border: none !important;
}

.ant-menu-item,
.ant-menu-submenu-title {
    background-color: #fff3e1;
    border-radius: 50px;
    padding: 16px 26px;
    margin-bottom: 24px !important;
    margin-top: 0px !important;
}

.ant-menu-item,
.ant-menu-submenu-title > span {
    color: @primary-color;
    fill: @primary-color;
}

.ant-menu-submenu-title .ant-menu-submenu-arrow {
    color: @primary-color;
    right: 26px;
}

.ant-menu-item::after {
    content: none !important;
}

.ant-menu-item-selected {
    background-color: @primary-color !important;
}

.ant-menu-item-selected > span,
.ant-menu-submenu-selected .anticon {
    color: #fff;
    fill: #fff;
}

.ant-menu-submenu {
    .ant-menu {
        background-color: transparent;
    }
    .ant-menu-item {
        background-color: transparent;
        padding: 0px 26px !important;
        text-align: right;
        margin-bottom: 10px !important;
        line-height: normal !important;
        height: unset !important;
    }
    .ant-menu-item > span {
        padding-bottom: 10px;
    }
    .ant-menu-item-selected {
        background-color: transparent !important;
    }
    .ant-menu-item-selected > span {
        color: @primary-color;
        text-decoration: underline;
        text-underline-offset: 10px;
    }
}

.ant-menu-submenu:has(.ant-menu-item-selected) {
    .ant-menu-submenu-title {
        background-color: @primary-color !important;
    }
    .ant-menu-submenu-title > span,
    .ant-menu-submenu-title > .ant-menu-submenu-arrow {
        color: #fff !important;
    }
}

.ant-divider {
    border-top-color: #cfcfcf;
}

.ant-divider-horizontal {
    margin: 32px 0px;
}

.ant-layout-header {
    background: #ffffff;
    height: auto;
    padding: 0px;
}

.ant-page-header-heading-extra {
    display: flex;
    align-items: center;
}

.ant-page-header-heading {
    align-items: center;
}

.ant-card {
    border-radius: 10px;
    background: #fff;
    box-shadow: 0px 20px 30px 0px rgba(0, 0, 0, 0.03);
    padding: 10px;
}

.ant-card-head {
    border-bottom: none;
}

.ant-card-head-wrapper {
    border-bottom: 1px solid #cfcfcf;
}

.ant-card-head-title {
    font-weight: 600;
}

.ant-notification-notice {
    border-radius: 10px;
}

.ant-dropdown-menu {
    border-radius: 10px;
    top: 10px;
    padding: 10px;
}

.ant-dropdown-menu-item {
    border-radius: 10px;
    font-weight: 500;
    margin-bottom: 5px;
}

.ant-dropdown-menu-item-active {
    background-color: #fff3e1 !important;
}

.ant-btn {
    border-radius: 10px;
    box-shadow: none !important;
    font-weight: 500;
    height: 44px;
}

.ant-btn .ant-btn-circle,
.ant-btn .ant-btn-link {
    padding-left: 0px;
    padding-right: 0px;
}

.ant-btn::after {
    content: none !important;
}

.ant-btn-lg {
    height: 60px;
}

.ant-btn-dark,
.ant-btn-dark:focus {
    background-color: #0a0a0a;
    border: none;
    color: #fff !important;
}

.ant-btn-dark:hover {
    background-color: #4b4b4b;
}

.ant-btn-dark:active {
    background-color: #6d6d6d;
}

.ant-btn-link {
    padding: 0;
}

.ant-modal-wrap {
    display: flex;
}

.ant-modal {
    width: fit-content !important;
    max-width: 750px;
    padding: 0 20px;
}

@media screen and (max-width: 767px) {
    .ant-modal {
        padding: 0;
    }
}

.ant-modal-content {
    border-radius: 20px;
}

.ant-modal-header {
    border: none;
    padding: 50px !important;
    padding-bottom: 0px !important;
    border-radius: 20px;
}

.ant-modal-title {
    font-size: 20px;
    font-weight: 600;
}

.ant-modal-body {
    padding: 50px !important;
}

@media screen and (max-width: 767px) {
    .ant-modal-header {
        padding: 24px !important;
    }
    .ant-modal-body {
        padding: 24px !important;
    }
}

.ant-modal-confirm-title {
    font-weight: 600 !important;
    font-size: 18px !important;
}

.ant-modal-confirm-content {
    margin-top: 32px !important;
}

.ant-modal-confirm-btns {
    margin-top: 32px !important;
}

.ant-modal-confirm-btns .ant-btn,
.ant-modal-confirm-btns .ant-btn-default {
    border: none;
    max-width: fit-content;
}

.ant-steps-item-active {
    font-weight: 600;
}

.ant-steps-icon {
    display: none;
}

.ant-steps-item-icon {
    position: relative;
}

.ant-steps-item-wait .ant-steps-item-icon,
.ant-steps-item-finish .ant-steps-item-icon {
    background-color: @primary-color;
    border: 5px solid #f8deb7;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
    background-color: #f8deb7;
    border: 5px solid #dba159;
}

.ant-steps-vertical > .ant-steps-item .ant-steps-item-content {
    min-height: 60px;
}

.ant-steps-item-tail {
    background-color: @primary-color;
}

.ant-steps-item-tail::after {
    content: none;
}

.ant-steps-vertical
    > .ant-steps-item
    > .ant-steps-item-container
    > .ant-steps-item-tail {
    left: 14px;
    width: 4px;
    height: calc(100% - 32px);
    top: 32px;
}

.ant-steps-horizontal .ant-steps-item-content {
    display: block;
    margin-top: 20px;
}

.ant-steps-item-title::after {
    content: none;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item-tail {
    display: block;
    position: absolute;
    width: calc(100% - 32px);
    height: 4px;
    top: 14px;
    left: 32px;
    padding: 0;
}

.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 0;
}

.ant-steps-horizontal
    .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-tail,
.ant-steps-horizontal
    .ant-steps-item:last-child
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after {
    display: block;
}

.ant-layout-sider-collapsed {
    display: none;
}

.ant-select-dropdown {
    border-radius: 10px;
    padding: 5px;
}

.ant-select-item {
    border-radius: 10px;
    margin: 0px 5px;
    padding: 10px;
}

@media screen and (max-width: 767px) {
    .ant-layout-content {
        padding: 20px 0px;
    }
    .ant-card-body {
        padding: 12px;
    }
}

.ant-modal-footer {
    border: none;
    padding: 0px 50px 50px 50px;
}

a.ant-btn {
    padding-top: 0px !important;
    line-height: 40px;
}

a.ant-btn.ant-btn-link {
    line-height: normal;
}

.ant-collapse {
    border: none;
}

.ant-collapse-header {
    background-color: #fff;
    border-color: #cfcfcf;
    padding-top: 0px !important;
    padding-bottom: 30px !important;
}

.ant-collapse-content-box {
    padding: 30px 16px !important;
}

.ant-checkbox-checked:hover::after {
    border-radius: 5px !important;
}

.ant-checkbox-inner {
    border-radius: 5px !important;
    height: 24px;
    width: 24px;
}

.ant-checkbox-inner::after {
    left: 31.5%;
}

.ant-menu-item a {
    color: @primary-color;
}