.header-user-dropdown-image {
    height: 24px;
    width: 24px;
    margin-right: 5px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
}

.header-language-image {
    height: 28px;
    width: 28px;
    border-radius: 14px;
}

.header-notifications-tab > .ant-tabs-content-holder {
    height: 350px;
    width: 350px;
    overflow: auto;
}

.header-notifications-tab li {
    padding: 10px;
}

.header-container {
    max-width: 1500px;
    width: 100%;
}

.mobile-header-logo {
    height: 40px;
    width: 40px;
}

.mobile-menu-btn {
    display: flex;
    font-size: 24px;
    position: relative;
    width: 24px;
}

.mobile-menu-btn > div {
    position: relative;
    opacity: 1;
    transition: all 500ms;
    transform: translateY(0px);
}

.mobile-menu-btn > .mobile-close-menu-icon,
.mobile-menu-btn.open > .mobile-open-menu-icon {
    position: absolute;
    opacity: 0;
    transform: translateY(-100px);
    left: 0;
}

.mobile-menu-btn.open > .mobile-close-menu-icon {
    position: relative;
    opacity: 1;
    transform: translateY(0px);
}
