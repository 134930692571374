.authed-layout-content {
    max-height: calc(100vh - 84px);
    overflow: auto;
    padding: 20px;
}

@media screen and (max-width: 767px) {
    .ant-layout-header {
        position: fixed;
        width: 100%;
        z-index: 1000;
    }
    .authed-layout-content {
        margin-top: 84px;
        max-height: unset;
        overflow: hidden;
        padding: 20px 0px;
    }
}
