.ant-form-item {
    position: relative;
    margin-bottom: 0px;
}

.ant-input {
    background-color: transparent !important;
    border: none !important;
    border-radius: 10px;
    font-weight: 500;
    padding: 16px 20px !important;
    box-shadow: none !important;
    z-index: 2;
}

.ant-input::placeholder {
    color: #a7a7a7;
    opacity: 0;
    transform: all 250ms;
}

.ant-select-selector {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    z-index: 2;
}

.ant-form-item:not(.ant-form-item-label-always-top) {
    .ant-form-item-label {
        color: #a7a7a7;
        position: absolute;
        padding: 0px 20px !important;
        line-height: 22px;
        top: 17px;
        transition: all 250ms;
        cursor: text !important;
        z-index: 1;
        white-space: nowrap !important;
    }
    .ant-form-item-label > label {
        color: #a7a7a7;
        cursor: text !important;
    }
}

.ant-form-item:hover,
.ant-form-item:has(.ant-input:focus) {
    .ant-form-item-label > label {
        color: #828282;
    }
    .ant-form-item-control-input-content {
        border-color: #4b4b4b;
    }
}

.ant-form-item:has(.ant-input:focus) {
    .ant-input::placeholder {
        opacity: 1;
    }
}

.ant-form-item:has(.ant-input:focus),
.ant-form-item:has(.ant-input[value]:not([value=""])),
.ant-form-item:has(.form-input-has-value) {
    .ant-form-item-label {
        top: 7px;
    }
    .ant-form-item-label > label {
        font-size: 12px;
    }
    .ant-input {
        padding: 24px 20px 8px 20px !important;
    }
}

.ant-form-item:has(.phone-input) {
    .ant-form-item-label {
        padding-left: 0 !important;
    }
    .ant-select-selection-search {
        display: flex;
        align-items: center;
    }
    .ant-select-selection-search input {
        text-align: right;
    }
    .ant-input {
        padding-left: 0px !important;
    }
    .ant-select-selection-item {
        color: #a7a7a7;
        font-weight: 500;
        text-align: right;
    }
}

.ant-form-item:hover {
    .ant-select-selection-item {
        color: #828282;
    }
}

.ant-form-item:has(.phone-input):has(.ant-input:focus),
.ant-form-item:has(.phone-input):has(.ant-input[value]:not([value=""])) {
    .ant-form-item-label {
        opacity: 0;
    }
    .ant-input {
        padding: 16px 20px !important;
        padding-left: 0px !important;
    }
    .ant-select-selection-item {
        color: #000;
    }
}

.ant-form-item-required::before {
    content: none !important;
}

.ant-form-item-label
    > label.ant-form-item-required:not(
        .ant-form-item-required-mark-optional
    )::after {
    content: "*";
    display: inline-block;
    margin-left: 4px;
    color: #f77;
    font-size: 14px;
    line-height: 1;
}

.ant-form-item-explain-error {
    color: #eb5757;
    font-size: 13px;
}

.ant-form-item-control-input-content {
    border: 1px solid #a7a7a7;
    border-radius: 10px;
    height: 56px;
}

.ant-form-item-has-error .ant-form-item-control-input-content {
    background-color: #fff0f0 !important;
    border-color: #f77 !important;
}

.ant-form-item-has-warning .ant-form-item-control-input-content {
    background-color: #f8deb7 !important;
    border-color: @primary-color !important;
}

.ant-input-affix-wrapper {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    padding-left: 0;
    padding-top: 0;
    padding-right: 20px;
}

.ant-input-group .ant-input {
    border-radius: 0px 10px 10px 0px !important;
    border-left: none;
}

.ant-input-group .ant-select-selector {
    background-color: transparent !important;
    border-color: #a7a7a7 !important;
    border-radius: 10px 0px 0px 10px !important;
    border-right: none !important;
    height: 100% !important;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-left: 20px !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #23232329;
}

// .ant-form-item-explain-connected {
//     position: absolute;
// }

// .ant-form-item:has(.ant-form-item-extra) {
//     .ant-form-item-explain-connected {
//         position: relative;
//     }
// }

.ant-form-item-extra {
    color: #4b4b4b;
    font-size: 14px;
    font-weight: 400;
}

.ant-form-item-label > label {
    display: block;
}

// Кастомный select

.ant-select-selection-item {
    font-weight: 500;
}

.ant-form-item:has(.ant-select-in-form-item):has(.ant-select-selection-item) {
    .ant-form-item-label {
        padding: 0 0 8px !important;
        position: relative;
        top: 0;
    }
    .ant-form-item-label > label {
        color: #4b4b4b;
    }
}

.ant-col:has(.ant-form-item-with-extra) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.ant-form-item:has(.ant-select-in-form-item) {
    .ant-select-selector {
        height: 56px;
        padding: 0;
    }
    .ant-select-selector .ant-select-selection-search {
        left: 0;
    }
    .ant-select-selector .ant-select-selection-item {
        left: 20px;
        display: flex;
        align-items: center;
    }
    .ant-select-selector .ant-select-selection-search-input {
        display: block;
        height: 56px;
        padding: 16px 20px;
    }
}

// Кастомный picker

.ant-picker {
    border: none;
    background-color: transparent;
    height: 100%;
    padding: 16px 20px;
}

.ant-picker-focused {
    border: none;
    box-shadow: none;
}

.ant-form-item:has(.ant-picker):not(.ant-form-item-label-always-top) {
    .ant-picker {
        opacity: 0;
    }
}

.ant-form-item:has(.ant-picker-focused),
.ant-form-item:has(.ant-picker):has(input[value]:not([value=""])) {
    .ant-picker {
        opacity: 1;
    }
    .ant-form-item-label {
        padding: 0 0 8px !important;
        position: relative;
        top: 0;
    }
    .ant-form-item-label > label {
        color: #4b4b4b;
    }
}

.ant-picker-input input {
    font-weight: 500;
}
