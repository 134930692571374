.authorization-page-content {
    display: flex;
    justify-content: center;
    overflow: auto;
    height: 100vh;
}

.authorization-card {
    max-width: 600px;
    width: 100%;
    margin: 100px 20px;
    height: max-content;
}

@media screen and (min-width: 768px) {
    .authorization-card .ant-card-body {
        padding: 60px;
    }
}

.authorization-page-logo {
    max-height: 50px;
    max-width: 50px;
    height: 100%;
    width: 100%;
}

.authorization-page-header-actions {
    position: fixed;
    top: 50px;
    right: 100px;
}

.authorization-page-header-actions button {
    background-color: #e2e2e2;
    height: 40px;
    width: 40px;
    border-radius: 50%;
}

@media screen and (max-width: 767px) {
    .authorization-page-header-actions {
        top: 32px;
        left: 20px;
        right: 20px;
        justify-content: space-between;
    }
}

.authorization-page-languages-dropdown {
    cursor: pointer;
}
