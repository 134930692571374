.ant-table-thead > tr > th {
    background-color: #fff;
    border: none;
    color: @text-color;
}
.ant-table-tbody > tr > td {
    border-bottom: 1px solid #cfcfcf;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
    padding: 20px 15px;
}

.ant-table-tbody:last-child tr:last-child td {
    border: none;
}

@media screen and (min-width: 768px) {
    .ant-table-thead::after {
        content: "";
        width: 100%;
        height: 1px;
        background-color: #cfcfcf;
        left: 0;
        position: absolute;
        z-index: 1000;
    }
}

@media screen and (max-width: 767px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td {
        font-size: 12px;
    }
}

.ant-table-content {
    border: 1px solid #a7a7a7;
    border-radius: 10px;
    padding: 0px 20px 20px 20px;
}

.ant-table-row-expand-icon-cell {
    display: none;
}

tbody .ant-table-cell {
    padding: 30px 15px !important;
}

.ant-table-expanded-row > td {
    background-color: transparent !important;
    border-color: #db8912 !important;
    border-width: 2px !important;
}

.ant-table-expanded-row-fixed .ant-divider {
    margin: 30px 0px;
}

.ant-table-cell::before {
    background-color: #dedede;
    width: 2px !important;
}

.ant-pagination-item-link {
    background-color: #e8e8e8 !important;
    border: none !important;
    border-radius: 6px !important;
}

.ant-pagination-item {
    border: none;
    font-size: 17px;
    min-width: 20px;
}

.ant-pagination-item a {
    padding: 0;
}

.ant-table-cell-fix-right {
    right: -20px !important;
}